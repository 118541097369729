<template>
  <div class="home">
    <div class="middle">
      <img src="..\src\images\login48.png" />
      <div class="middlelog">
        <!-- <div class="tex">考试管理系统</div> -->
        <div class="tex">
          临沂市电梯行业协会
          <div class="tex-bottom">
           场（厂）内机动车辆考培系统
          </div>
        </div>
        <div class="user">
          <el-form
            :model="account"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            登录帐号：
            <el-form-item prop="login_name">
              <el-input
                class="ruleForm_input"
                v-model="account.login_name"
                placeholder="请输入用户名/邮箱/手机号/身份证"
              ></el-input>
            </el-form-item>
            登录密码：
            <el-form-item prop="password">
              <el-input
                class="ruleForm_input"
                show-password
                v-model="account.password"
                placeholder="请输入6位以上字符"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="Forgot" @click="forgotPassword()">忘记密码</div>
          <!-- <div class="Sign">自动登录</div> -->
          <button class="login" @click="login()">登 录</button>
          <!-- <div class="interpretation" @click="jumpRegister()">
            还没有账号，<span>立即免费注册</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {logins} from '@/api/index'
import { login } from "@/api/index";
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value !== "") {
        if (value.length < 6) {
          callback(new Error("请输入至少6位的密码"));
          return false;
        } else if (
          !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(value)
        ) {
          // callback(new Error("密码必须包含字母、数字"));
          // return false;
        } else {
          callback();
        }
      }
    };
    return {
      account: {
        login_name: "",
        password: "",
      },
      rules: {
        login_name: [
          { required: true, message: "请输入登陆账号", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 登录
    login() {
      login(this.account).then((res) => {
        console.log(res);
        if (res.code == 1) {
          localStorage.setItem("token", res.data.token);
          // localStorage.setItem('tokenStartTime', new Date().getTime());  //  存储token开始时间
          this.$router.push("/home");
          this.$message({
            message: res.info,
            type: "success",
          });
        } else {
          this.$message.error(res.info);
        }
      });
    },
    // 忘记密码
    forgotPassword() {
      this.$router.push("/email");
    },
    // 跳转注册页面
    jumpRegister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: #2971f7;
  border-radius: 0px 0px 0px 0px;
}
.middle {
  display: flex;
  justify-content: center;
  padding: 10% 0;
}
.middlelog {
  width: 589px;
  background: #fff;
  .tex {
    margin-top: 41px;
    font-style: Alibaba PuHuiTi 2-75 SemiBold;
    font-size: 30px;
    // color: #5f748f;
    color: #444a52;
    font-weight: 550;
  }
  .tex-bottom{
    margin-top: 10px;
    font-style: Alibaba PuHuiTi 2-75 SemiBold;
    font-size: 18px;
    color: #2971f7;
  }
  .user {
    margin-top: 8.41%;
    float: left;
    margin-left: 100px;
    width: 108px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #5f748f;
    line-height: 40px;
    .demo-ruleForm {
      // margin-left: -140px;
      .el-form-item__label {
        width: 80px;
      }
      .el-form-item {
        margin: 3px 0;
        ::v-deep .el-form-item__error {
          width: 200px;
          margin-left: -100px;
        }
      }
      .ruleForm_input {
        margin-left: -100px;
        width: 377px;
      }
    }

    .Forgot {
      // border: 1px solid;
      width: 70px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #fcb053;
      cursor: pointer;
    }
    .Sign {
      float: right;
      margin: -20px -290px 0px 0px;
      color: #5f748f;
      height: 20px;
      font-size: 14px;
      cursor: pointer;
    }
    .login {
      width: 378px;
      height: 40px;
      background: #2971f7;
      border-radius: 4px 4px 4px 4px;
      border: none;
      color: #fff;
      margin-top: 20px;
    }
    .interpretation {
      width: 178px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #5f748f;
      line-height: 0px;
      margin-top: 20px;
      cursor: pointer;
      span {
        color: #2971f7;
        font-size: 14px;
      }
    }
  }
}
</style>
